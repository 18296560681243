import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useLocation } from 'react-router';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import { theme } from './Theme';
import '../globalStyles';
import NewsletterPopup from './Newsletter/NewsletterPopup';
import SignUpPage from './Auth/Signup/SignUpPage';
import LogInPage from './Auth/LogInPage';
import useChannelCheck from '../utils/useChannelCheck';
import { CheckIndexing } from './CheckIndexing';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: <LoadingPage />
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

function Shop() {
  const { routes } = useShopConfig();
  const { isB2B } = useChannelCheck();
  const location = useLocation();
  const { selectedChannel } = useContext(ChannelContext);

  if (typeof window !== 'undefined') {
    if (selectedChannel.id !== 3 && location.pathname.includes('b2b')) {
      window.location.href =
        '/b2b?culture=sv-SE&currency=SEK&channelCountry=SE&channel=b2b';
    }
  }

  return (
    <GenericError>
      <ModalProvider>
        <Container>
          <LoadingBar color={theme.colors.loadingBar} />
          <CustomFont
            primaryFont={theme.fonts.primary}
            injectCss={loadFontCss}
          />
          <Helmet
            titleTemplate="%s - Designed Posters"
            defaultTitle="Designed Posters"
          >
            <script
              type="text/javascript"
              src="https://fast.fonts.net/jsapi/a135aa25-294c-4739-9c02-824e0ebc092b.js"
            ></script>
          </Helmet>
          <CheckIndexing />
          <ProductListProvider queries={productListQueries}>
            <Header />
            {!isB2B && <NewsletterPopup />}
            <Content>
              <PaginationProvider defaultProductsPerPage={42}>
                <Switch>
                  <Route exact path="/">
                    <LoadableStartPage />
                  </Route>
                  <Route path="/favourites">
                    <Favourites />
                  </Route>
                  <Route path={routes.search.path}>
                    <LoadableSearchPage />
                  </Route>
                  <Route path={routes.signup.path}>
                    <SignUpPage />
                  </Route>
                  <Route path={routes.login.path}>
                    <LogInPage />
                  </Route>
                  <Route path={routes.stores.path}>
                    <StoreLocator />
                  </Route>
                  <Route path={`${routes.store.path}/:id`}>
                    <Store />
                  </Route>
                  <Route path={routes.tree.path}>
                    <NavTreePage />
                  </Route>
                  <Route path={routes.myPages.path}>
                    <LoadableMyPages />
                  </Route>
                  <Route exact path={routes.forgotPassword.path}>
                    <ForgotPassword />
                  </Route>
                  <Route path={`${routes.resetPassword.path}/:token`}>
                    <ResetPassword />
                  </Route>
                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        productPage={LoadableProductPage}
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        pageQuery={PagePreviewQuery}
                        categoryPage={LoadableCategoryPage}
                        contentPage={LoadableContentPage}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={LoadableCategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
            </Content>
            <Footer />
          </ProductListProvider>
          <ModalRoot />
          <ScrollRestorationHandler
            ignoreForRouteTypes={['sortOrderChange', 'filterChange']}
          />
        </Container>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;

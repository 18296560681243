import { Above, Below } from '@jetshop/ui/Breakpoints';
import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';
import t from '@jetshop/intl';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import SuggestedTerm from './SuggestedTerm';
import * as React from 'react';
import { styled } from 'linaria/react';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import { ProductCard } from '../../../CategoryPage/ProductCard';
import { theme } from '../../../Theme';

var __rest =
  (this && this.__rest) ||
  function(s, e) {
    var t = {};

    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];

    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (
          e.indexOf(p[i]) < 0 &&
          Object.prototype.propertyIsEnumerable.call(s, p[i])
        )
          t[p[i]] = s[p[i]];
      }
    return t;
  };

const Wrapper = styled('div')`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;
const InputWrapper = styled('div')`
  position: relative;
`;
const SearchFieldWrapper = styled('div')`
  width: 100%;
  font-size: 16px;
  font-weight: normal;
`;
const Flyout = styled('div')`
  position: absolute;
  background: white;
  z-index: 999;
  border: 1px solid #e8e8e8;
  width: 100%;
  top: 100%;
  left: 0;
`;
const CancelButton = styled('button')`
  font-size: 0.875rem;
  border: 0;
  background: transparent;
  color: #878787;
  margin-left: 1rem;
`;
const SearchResultWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: #f9f9f9;

  ${theme.below.lg} {
    flex-wrap: wrap;
  }

  h3 {
    font-size: 14px;
    margin-bottom: 3px;
  }
`;
const ProductResults = styled('div')`
  background: white;
  display: flex;
  flex: 3;
  padding: 30px;
  flex-wrap: wrap;

  ${theme.below.lg} {
    padding: 20px;
    width: 100%;
    flex: none;
    display: block;
  }

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    ${theme.below.lg} {
      margin-left: -5px;
      margin-right: -5px;
    }

    li.product-card {
      margin-bottom: 30px;
      width: 33.3%;

      .news-badge-wrapper {
        display: none;
      }

      ${theme.below.lg} {
        margin-bottom: 0;
        width: 100%;
        min-width: 35vw;
        max-width: 35vw;
        flex: none;
        a {
          margin-bottom: 0;
        }
      }

      > a {
        display: flex;
        align-items: flex-start;

        ${theme.below.lg} {
          display: block;
        }

        > div,
        > section {
          flex: 1;
        }

        .image-wrapper {
          max-width: 120px;
          ${theme.below.lg} {
            width: 100%;
            max-width: 100%;
          }
        }
        .product-card-detail {
          padding-top: 0;
          h3 {
            text-align: left;
            white-space: pre-wrap;
            ${theme.below.lg} {
              text-align: center;
            }
          }
        }
        .price-package-wrapper {
          ${theme.above.lg} {
            justify-content: flex-start;
            div {
              margin-right: 5px;
              justify-content: flex-start;
              text-align: left;
            }
          }
        }
      }
    }
  }
`;
const CategoryResults = styled('div')`
  background: #f9f9f9;
  flex: 1;
  padding: 30px 50px;

  ${theme.below.lg} {
    padding: 20px;
    width: 100%;
    flex: none;
  }

  li {
    font-size: 14px;
  }

  a {
    font-size: 14px;
    display: block;
    padding: 3px 0;
    text-decoration: none;
    color: #333;
    &:hover {
      color: #c4c4c4;
    }
  }
`;
const Column = styled('div')`
  ${theme.below.lg} {
    margin-bottom: 20px;
  }
  &.product-ul {
    flex: 4;
    padding-left: 20px;

    ${theme.below.lg} {
      padding: 0;
      display: flex;
      flex-wrap: nowrap !important;
      justify-content: flex-start;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track,
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        display: none;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;
const TriggerSearchButton = styled('button')`
  text-decoration: underline;
  font-size: 14px;
  appearance: none;
  border: 0;
  background: none;
  border-radius: 0;
  margin-top: 20px;
  ${theme.below.lg} {
    margin-top: 0;
  }
`;

const SearchResults = ({
  term,
  result,
  loading,
  onClick,
  triggerSearch
  //clearSearch,
  //setMobileSearchOpen
}) => {
  var _a, _b;

  const products = result.products;
  const categories = result.categories;

  if (
    !loading &&
    products.result.length === 0 &&
    ((_a = categories) === null || _a === void 0
      ? void 0
      : _a.result.length) === 0
  ) {
    return null;
  }

  return (
    <SearchResultWrapper>
      <CategoryResults>
        {((_b = categories) === null || _b === void 0
          ? void 0
          : _b.result.length) > 0 ? (
          <React.Fragment>
            <h3>{t('Search result')}:</h3>
            <ul
              style={
                loading
                  ? {
                      opacity: 0.5
                    }
                  : null
              }
            >
              {categories.result.map(item => (
                <SuggestedTerm
                  term={term}
                  onClick={onClick}
                  key={item.id}
                  item={item}
                />
              ))}
            </ul>
          </React.Fragment>
        ) : (
          <>
            <h3>{t('Search result:')}</h3>
            <ul>
              <li>{t('Did not find any matching categories.')}</li>
            </ul>
          </>
        )}
      </CategoryResults>
      <ProductResults>
        <React.Fragment>
          <Column>
            {products.result.length > 0 ? (
              <>
                <h3>{t('Best suggestions:')}</h3>
                <Above breakpoint="lg">
                  <TriggerSearchButton
                    onClick={() => {
                      triggerSearch();
                      //clearSearch();
                      //setMobileSearchOpen(false);
                    }}
                  >
                    {t('Do a complete search')}
                  </TriggerSearchButton>
                </Above>
              </>
            ) : (
              <>
                <h3>{t('No product suggestions found.')}</h3>
                <TriggerSearchButton
                  onClick={() => {
                    triggerSearch();
                    //clearSearch();
                    //setMobileSearchOpen(false);
                  }}
                >
                  {t('Do a complete search')}
                </TriggerSearchButton>
              </>
            )}
          </Column>
          <Column className="product-ul">
            {products.result.length > 0 ? (
              <>
                <ul
                  style={
                    loading
                      ? {
                          opacity: 0.5
                        }
                      : null
                  }
                >
                  {products.result.map(item => (
                    <ProductCard
                      term={term}
                      onClick={onClick}
                      key={item.id}
                      item={item}
                      product={item}
                    >
                      {''}
                    </ProductCard>
                  ))}
                </ul>
              </>
            ) : null}
          </Column>
          <Below breakpoint="lg">
            <TriggerSearchButton
              onClick={() => {
                triggerSearch();
                //clearSearch();
                //setMobileSearchOpen(false);
              }}
            >
              {t('Do a complete search')}
            </TriggerSearchButton>
          </Below>
        </React.Fragment>
      </ProductResults>
    </SearchResultWrapper>
  );
};

const SearchField = _a => {
  var { initialValue, className, placeholder = '', autocompleteQuery } = _a,
    rest = __rest(_a, [
      'initialValue',
      'className',
      'placeholder',
      'autocompleteQuery'
    ]);

  throwErrorInDev(
    !autocompleteQuery,
    `You forgot to pass 'autocompleteQuery' to SearchField`
  );
  return (
    <SearchAutoCompleteContainer
      {...Object.assign(
        {
          autocompleteQuery: autocompleteQuery,
          initialValue: initialValue,
          focusOnLoad: true
        },
        rest
      )}
    >
      {searchField => (
        <Wrapper className={className}>
          <SearchFieldWrapper>
            <FlyoutTrigger showCover={false} id="searchFlyout">
              {({ showTarget, hideTarget }) => (
                <React.Fragment>
                  <InputWrapper>
                    <input
                      {...Object.assign(
                        {
                          placeholder: placeholder
                        },
                        searchField.getInputProps({
                          onFocus: showTarget
                        }),
                        {
                          'data-flight-search-autocomplete-field': ''
                        }
                      )}
                    />
                    <CancelButton
                      {...Object.assign(
                        {},
                        searchField.getCancelProps({
                          onClick: hideTarget
                        })
                      )}
                    >
                      {t('Cancel')}
                    </CancelButton>
                  </InputWrapper>
                </React.Fragment>
              )}
            </FlyoutTrigger>
            <FlyoutTarget id="searchFlyout">
              {flyout => {
                var _a, _b;

                const hasResults =
                  ((_a = searchField.result) === null || _a === void 0
                    ? void 0
                    : _a.products.result.length) > 0 ||
                  ((_b = searchField.result) === null || _b === void 0
                    ? void 0
                    : _b.categories.result.length) > 0;
                const { loading, isDirty, isOpen } = searchField;
                const showFlyout =
                  isDirty && !loading && hasResults && isOpen && flyout.isOpen;

                if (showFlyout) {
                  return (
                    <Flyout>
                      <div {...Object.assign({}, searchField.getFlyoutProps())}>
                        {searchField.isDirty && (
                          <React.Fragment>
                            <SearchResults
                              term={searchField.term}
                              result={searchField.result}
                              onClick={flyout.hideTarget}
                              loading={searchField.loading}
                              triggerSearch={searchField.triggerSearch}
                              //clearSearch={searchField.clearSearch}
                              //setMobileSearchOpen={setMobileSearchOpen}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </Flyout>
                  );
                }

                return null;
              }}
            </FlyoutTarget>
          </SearchFieldWrapper>
        </Wrapper>
      )}
    </SearchAutoCompleteContainer>
  );
};

SearchField.Flyout = Flyout;
SearchField.Wrapper = SearchFieldWrapper;
SearchField.Cancel = CancelButton;
export default SearchField;

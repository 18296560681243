import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from '../../../Theme';

const Wrapper = styled('div')`
  padding: 0;
  margin-bottom: 1rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1rem;
    margin-top: -8px;
    width: 100%;
    //padding-left: 1.2rem;

    &.lv2 {
      padding-right: 15px;
    }
    &.lv3 {
      margin-bottom: 5px;
      margin-top: 0;
      padding-left: 5px;
    }
  }

  li {
    color: #8f8f8f;
    flex: auto;
    line-height: 1.5;
    max-width: 100%;
    padding: 0;
    width: 100%;
    a {
      opacity: 1;
      padding: 0;
      display: block;
      font-size: 14px;
      transition: all ease 0.3s;
      :hover,
      &.active {
        color: ${theme.colors.green};
      }

      &.allbtn {
        font-weight: bold;
      }
    }
  }

  a {
    text-decoration: none;
    color: ${theme.colors.black};
    opacity: 0.8;
    display: block;
    :hover {
      opacity: 1;
    }
  }
`;

const Heading = styled('h2')`
  font-size: 16px;
  font-weight: 600;
  svg {
    width: 18px !important;
    height: 18px !important;
    vertical-align: middle;
    margin-top: -2px;
  }
  a {
    transition: all ease 0.3s;
    margin-bottom: 0.5rem;
  }
`;

const SubMenuSection = ({
  heading,
  categories,
  onMouseOver,
  onSelect = () => {}
}) => {
  return (
    <Wrapper onMouseOver={onMouseOver}>
      {!categories ? (
        <Heading>
          <CategoryLink onClick={onSelect} category={heading}>
            {heading.name}
          </CategoryLink>
        </Heading>
      ) : null}
      <ul className="lv2">
        {categories.map(cat => (
          <li key={cat.id}>
            <CategoryLink
              onClick={onSelect}
              category={cat}
              className={
                cat?.name.toLowerCase().includes('se alla') ? 'allbtn' : ''
              }
            >
              {cat.name}
            </CategoryLink>

            {cat?.subcategories?.length > 0 ? (
              <ul className="lv3">
                {cat.subcategories.map((cat, index) => (
                  <CategoryLink onClick={onSelect} category={cat} key={index}>
                    {cat.name}
                  </CategoryLink>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default SubMenuSection;

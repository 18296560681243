import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { styled } from 'linaria/react';
import { useQuery } from '@apollo/react-hooks';
import NewsletterQuery from '../Layout/Footer/NewsletterQuery.gql';
import NewsletterField from './NewsletterField';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { useCookies } from 'react-cookie';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image';

const InfoModalWrapper = styled(ReactModal)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  pointer-events: none;
  ${theme.below.lg} {
    &.data-flight-image-container {
      padding-bottom: 75% !important;
    }
  }
`;

const ModalInside = styled('div')`
  margin: 10px;
  background-color: white;
  width: 100vw;
  max-width: 780px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  pointer-events: all;
  max-height: 85vh;
  min-height: 120px;
  overflow-y: scroll;
  svg.close-icon {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    color: #333;
    use,
    g,
    path {
      fill: black;
    }
  }
`;

const Inner = styled('div')`
  text-align: center;
  font-size: 1.5rem;
  display: block;
  ${theme.below.lg} {
    max-height: 100%;
  }
`;

const NewsletterWrapper = styled('div')`
  display: flex;
  flex-direction: row;

  ${theme.below.lg} {
    height: 80vh;
    flex-direction: column;
    h2 {
      font-size: 1em !important;
      line-height: 1em !important;
    }
  }
  h2 {
    font-size: 1.5em;
    letter-spacing: 0 !important;
  }
`;

const NewsletterHeader = styled('div')`
  padding: 70px 40px;
  justify-content: stretch;
  max-width: 52%;
  align-self: center;
  ${theme.below.lg} {
    padding: 0 20px;
    max-width: 100%;
    height: fit-content;
    flex-grow: 1;
  }
  h2 {
    font-size: 1.1em;
    line-height: normal;
    text-align: left;
    font-weight: 600;
  }
`;

const ImageWrapper = styled('div')`
  div {
    height: 100% !important;
  }
  justify-content: stretch;
  width: 50%;
  img {
    width: 100%;
    height: auto;
  }
  ${theme.below.lg} {
    width: 100%;
    height: 100%;
    flex-shrink: 1;
  }
`;

function NewsletterPopup() {
  const [cookies, setCookie] = useCookies();
  const [open, setOpen] = useState(false);
  const hidePopupNewsletter = cookies?.hidePopupNewsletter;
  const { data, loading, error } = useQuery(NewsletterQuery, {
    variables: {
      id: 234
    },
    errorPolicy: 'ignore'
  });
  if (loading || error) return null;

  const kampanj = data?.category?.subcategories[2];
  const text = data?.category?.subcategories[1];
  const title = data?.category?.subcategories[0];
  const image = data?.category?.images;

  const handleCloseClick = () => {
    setOpen(false);
    setCookie('hidePopupNewsletter', true, [{ path: '/' }]);
  };

  //Checking if the NewsletterPopup already been closed, IF so don't show again.
  if (hidePopupNewsletter) {
    return null;
  }

  setTimeout(() => {
    if (!hidePopupNewsletter) {
      setOpen(true);
    } else {
      return;
    }
  }, 20000);
  return data?.category?.subcategories?.length > 0 ? (
    <InfoModalWrapper
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
    >
      <ModalInside>
        <Cross
          className="close-icon"
          onClick={() => {
            handleCloseClick();
          }}
        />
        <Inner>
          <NewsletterWrapper>
            {image && (
              <ImageWrapper>
                <Image
                  fillAvailableSpace
                  aspect="1:1"
                  cover
                  quality={100}
                  sizes={'660'}
                  src={image}
                  alt={image}
                />
              </ImageWrapper>
            )}
            <NewsletterHeader>
              <NewsletterField
                title={title}
                text={text}
                kampanj={kampanj}
                closeClick={handleCloseClick}
              />
            </NewsletterHeader>
          </NewsletterWrapper>
        </Inner>
      </ModalInside>
    </InfoModalWrapper>
  ) : null;
}

export default NewsletterPopup;
